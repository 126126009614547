import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
//import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <GatsbyImage
          image={imageNodes[0].node.childImageSharp.gatsbyImageData}
          objectPosition="50% 0%"
          layout="constrained"
          alt=""
        />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        伝統にとらわれない現代俳句という新しい領域を開き、日本の俳句文学の発展に大きく寄与してきた俳人金子兜太。撮影当時90歳であったが、現役として俳壇での創作はもとより、さまざまな分野で精力的に発信を続けていた。金子さんに出会った人は、その前向きに生きる力、人柄、言動の全てに感動し惹きつけられる。「自らの肉体が五・七・五」と語る俳句人生の原点の地「秩父」と「戦争への強い憤り」。そしてたどり着いた「定住漂泊」。<br />
        2018年2月98歳で没
      </p>
    );
  };

  const summaryData = {
    scopes: ['All'],
    awards: ['映文連アワード2010 最優秀作品賞（グランプリ）'],
    outline: OutlineContent
  };

  /*
  const relatedLinks = [
    {text: "", href: ""}
  ];
  */

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
      //relatedLinks={relatedLinks}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(1, 3)} column={1} />
        <WorkDetailImages images={imageNodes.slice(3, 5)} column={2} />
        <WorkDetailImages images={imageNodes.slice(5, 6)} column={1} />
        <WorkDetailImages images={imageNodes.slice(6, 12)} column={2} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/kaneko_touta"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/kaneko_touta.*"}) {
      publicURL
    }
  }
`;
